import { createStore, applyMiddleware, compose } from 'redux';
import reducers from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

function configureStore(preloadedState: any) {

    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(reducers, preloadedState, composeEnhancers(
        applyMiddleware(...middlewares)
    ));

    // @ts-ignore
    sagaMiddleware.run(rootSaga);

    // @ts-ignore
    if (module.hot) {
        // @ts-ignore
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

// @ts-ignore
const store = configureStore();

export default store;

