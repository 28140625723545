import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Icon = <LoadingOutlined style={{fontSize: 35}} spin/>;

interface Props {
    align?: string;
    cover?: string;
}

const Loading: React.FC<Props> = ({align = 'center', cover = 'inline'}) => {
    return (
        <div className={`loading text-${align} cover-${cover}`}>
            <Spin indicator={Icon}/>
        </div>
    );
};

export default Loading;