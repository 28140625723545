import { auth, googleAuthProvider, facebookAuthProvider } from '../auth/FirebaseAuth';

const FirebaseService = {};

// @ts-ignore
FirebaseService.signInEmailRequest = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password).then(user => user).catch(err => err);

// @ts-ignore
FirebaseService.signInEmailRequest = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password).then(user => user).catch(err => err);

// @ts-ignore
FirebaseService.signOutRequest = async () =>
    await auth.signOut().then(user => user).catch(err => err);

// @ts-ignore
FirebaseService.signInGoogleRequest = async () =>
    await auth.signInWithPopup(googleAuthProvider).then(user => user).catch(err => err);

// @ts-ignore
FirebaseService.signInFacebookRequest = async () =>
    await auth.signInWithPopup(facebookAuthProvider).then(user => user).catch(err => err);

// @ts-ignore
FirebaseService.signUpEmailRequest = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password).then(user => user).catch(err => err);

export default FirebaseService;