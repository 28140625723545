import {
    DashboardOutlined,
    UnorderedListOutlined,
    TeamOutlined,
    HeartOutlined,
    MedicineBoxOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    PicLeftOutlined,
    EuroOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from './AppConfig';

const dashBoardNavTree = [
    {
        key: '',
        path: ``,
        title: 'sidenav.dashboard',
        icon: '',
        breadcrumb: false,
        submenu: [
            {
                key: 'dashboard',
                path: `${APP_PREFIX_PATH}/dashboard`,
                title: 'sidenav.dashboard',
                icon: DashboardOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'breeder',
                path: `${APP_PREFIX_PATH}/breeder/list`,
                title: 'sidenav.breederList',
                icon: TeamOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'charity',
                path: `${APP_PREFIX_PATH}/charity/list`,
                title: 'sidenav.charityList',
                icon: HeartOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'vet',
                path: `${APP_PREFIX_PATH}/vet/list`,
                title: 'sidenav.vetList',
                icon: MedicineBoxOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'sub-vet',
                path: `${APP_PREFIX_PATH}/sub-vet/list`,
                title: 'sidenav.subVetList',
                icon: AppstoreAddOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'buyer',
                path: `${APP_PREFIX_PATH}/buyer/list`,
                title: 'sidenav.buyerList',
                icon: UserOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'advert',
                path: `${APP_PREFIX_PATH}/advert/list`,
                title: 'sidenav.advertList',
                icon: PicLeftOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'microchip',
                path: `${APP_PREFIX_PATH}/microchip/list`,
                title: 'sidenav.microchipList',
                icon: UnorderedListOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'order',
                path: `${APP_PREFIX_PATH}/order/list`,
                title: 'sidenav.orderList',
                icon: EuroOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'alert',
                path: `${APP_PREFIX_PATH}/pet-alert/list`,
                title: 'sidenav.petAlertList',
                icon: UnorderedListOutlined,
                breadcrumb: false,
                submenu: []
            }
        ]
    }
];

const navigationConfig = [
    ...dashBoardNavTree
];

export default navigationConfig;
