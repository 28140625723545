import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from '../../configs/NavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from '../../constants/ThemeConstant';
import utils from '../../utils';
import { onMobileNavToggle } from '../../redux/actions';
import { LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { AUTH_PREFIX_PATH } from '../../configs/AppConfig';

const {SubMenu} = Menu;
const {useBreakpoint} = Grid;

const setLocale = (isLocaleOn: any, localeKey: any) =>
    isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

const setDefaultOpen = (key: any) => {
    let keyList = [];
    let keyString = '';
    if (key) {
        const arr = key.split('-');
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const SideNavContent: React.FC = (props: any) => {
    let history = useHistory();
    const {sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle} = props;
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
    const closeMobileNav = () => {
        if (isMobile) {
            onMobileNavToggle(false);
        }
    };

    const userRoles: any = JSON.parse(localStorage.getItem('currentUser') as string)?.role;
    const logout = () => {
        history.push(`${AUTH_PREFIX_PATH}/login`);
        localStorage.clear();
    };

    let conditionalNav = navigationConfig.map(nav => ({...nav}));
    if (userRoles?.isDefault === false) {
        // eslint-disable-next-line array-callback-return
        conditionalNav[0].submenu = conditionalNav[0].submenu.filter((sub) => {
            const listPermission = `${sub.key}_list`;
            const addPermission = `${sub.key}_add`;
            for (const perm of userRoles?.permissions) {
                if (sub.key === 'dashboard' || perm.name === listPermission || perm.name === addPermission) {
                    return sub;
                }
            }
        });
    }

    return (
        <Menu
            theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
            mode="inline"
            style={{height: '100%', borderRight: 0}}
            defaultSelectedKeys={[routeInfo?.key]}
            defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
            className={hideGroupTitle ? 'hide-group-title' : ''}
        >
            {conditionalNav.map((menu) =>
                menu.submenu.length > 0 ? (
                    <Menu.ItemGroup
                        key={menu.key}
                        title={setLocale(localization, menu.title)}
                    >
                        {menu.submenu.map((subMenuFirst) => (
                                subMenuFirst.submenu.length > 0 ? (
                                    <SubMenu
                                        icon={
                                            subMenuFirst.icon ? (
                                                <Icon type={subMenuFirst?.icon}/>
                                            ) : null
                                        }
                                        key={subMenuFirst.key}
                                        title={setLocale(localization, subMenuFirst.title)}
                                    >
                                        {subMenuFirst.submenu.map((subMenuSecond: any) => (
                                            <Menu.Item key={subMenuSecond.key}>
                                                {subMenuSecond.icon ? (
                                                    <Icon type={subMenuSecond?.icon}/>
                                                ) : null}
                                                <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                                <Link onClick={() => closeMobileNav()} to={subMenuSecond.path}/>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item key={subMenuFirst.key}>
                                        {subMenuFirst.icon ? <Icon type={subMenuFirst.icon}/> : null}
                                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                                        <Link onClick={() => closeMobileNav()} to={subMenuFirst.path}/>
                                    </Menu.Item>
                                )
                            )
                        )}
                    </Menu.ItemGroup>
                ) : (
                    <Menu.Item key={menu.key}>
                        {menu.icon ? <Icon type={menu?.icon}/> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path}/> : null}
                    </Menu.Item>
                )
            )}

            {/*logout button*/}
            <Menu.Item onClick={logout}>
                <Icon type={LogoutOutlined}/>
                <span>Logout</span>
            </Menu.Item>

        </Menu>
    );
};

const TopNavContent: React.FC = (props: any) => {
    const {topNavColor, localization} = props;
    return (
        <Menu mode="horizontal" style={{backgroundColor: topNavColor}}>
            {navigationConfig.map((menu) =>
                menu.submenu.length > 0 ? (
                    <SubMenu
                        key={menu.key}
                        popupClassName="top-nav-menu"
                        title={
                            <span>
                {menu.icon ? <Icon type={menu?.icon}/> : null}
                                <span>{setLocale(localization, menu.title)}</span>
              </span>
                        }
                    >
                        {menu.submenu.map((subMenuFirst) =>
                                subMenuFirst.submenu.length > 0 ? (
                                    <SubMenu
                                        key={subMenuFirst.key}
                                        icon={
                                            subMenuFirst.icon ? (
                                                <Icon type={subMenuFirst?.icon}/>
                                            ) : null
                                        }
                                        title={setLocale(localization, subMenuFirst.title)}
                                    >
                                        {subMenuFirst.submenu.map((subMenuSecond: any) => (
                                            <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                                <Link to={subMenuSecond.path}/>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item key={subMenuFirst.key}>
                                        {subMenuFirst.icon ? (
                                            <Icon type={subMenuFirst?.icon}/>
                                        ) : null}
                                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                                        <Link to={subMenuFirst.path}/>
                                    </Menu.Item>
                                )
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        {menu.icon ? <Icon type={menu?.icon}/> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path}/> : null}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
};

interface Props {
    type: any;
}

const MenuContent: React.FC<Props> = (props) => {
    return props.type === NAV_TYPE_SIDE ? (
        <SideNavContent {...props} />
    ) : (
        <TopNavContent {...props} />
    );
};

// @ts-ignore
const mapStateToProps = ({theme}) => {
    const {sideNavTheme, topNavColor} = theme;
    return {sideNavTheme, topNavColor};
};

export default connect(mapStateToProps, {onMobileNavToggle})(MenuContent);
