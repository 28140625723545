import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AUTH_PREFIX_PATH } from '../../../configs/AppConfig';

// @ts-ignore
function PrivateRoute({component: Component, ...rest}) {

    return (
        <Route {...rest}
               render={props =>

                   localStorage.getItem('access_token') ? (<Component {...props} />) : <Redirect to={{
                       pathname: `${AUTH_PREFIX_PATH}/login`, state: {from: props.location}
                   }}/>

               }
        />
    );

}

export default PrivateRoute;
