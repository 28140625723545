import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

export const NavPanel: React.FC = () => {

    return (
        <>
            <Menu mode="horizontal">
                <Menu.Item>
                    <Link to="#"> Guide </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#"> Support </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#"> Cookie policy </Link>
                </Menu.Item>
            </Menu>
        </>
    );

};

// @ts-ignore
const mapStateToProps = ({theme}) => {
    const {locale} = theme;
    return {locale};
};

export default connect(mapStateToProps)(NavPanel);
