import React from 'react';

interface Props {
    type?: any;
    className?: string;
}

export const Icon: React.FC<Props> = ({type, className}) => {

    return (
        <>{React.createElement(type, {className: className})}</>
    );

};

export default Icon;
