import React from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { NAV_TYPE_SIDE } from '../../constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent';
import { onMobileNavToggle } from '../../redux/actions';
import Logo from './Logo';
import Flex from '../shared-components/Flex';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface Props {
    sideNavTheme?: any;
    mobileNav?: any;
    onMobileNavToggle?: any;
    routeInfo?: any;
    hideGroupTitle?: any;
    localization?: boolean;
}

export const MobileNav: React.FC<Props> = ({
                                               sideNavTheme,
                                               mobileNav,
                                               onMobileNavToggle,
                                               routeInfo,
                                               hideGroupTitle,
                                               localization = true
                                           }) => {
    const props = {sideNavTheme, routeInfo, hideGroupTitle, localization};

    const onClose = () => {
        onMobileNavToggle(false);
    };

    return (
        <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            visible={mobileNav}
            bodyStyle={{padding: 5}}
        >
            <Flex flexDirection="column" className="h-100">
                <Flex justifyContent="between" alignItems="center">
                    <Logo mobileLogo={true}/>
                    <div className="nav-close" onClick={() => onClose()}>
                        <ArrowLeftOutlined/>
                    </div>
                </Flex>
                <div className="mobile-nav-menu">
                    <Scrollbars autoHide>
                        <MenuContent type={NAV_TYPE_SIDE} {...props} />
                    </Scrollbars>
                </div>
            </Flex>
        </Drawer>
    );
};

// @ts-ignore
const mapStateToProps = ({theme}) => {
    const {navCollapsed, sideNavTheme, mobileNav} = theme;
    return {navCollapsed, sideNavTheme, mobileNav};
};

export default connect(mapStateToProps, {onMobileNavToggle})(MobileNav);
