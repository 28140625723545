import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Loading from '../../components/shared-components/Loading';
import { APP_PREFIX_PATH } from '../../configs/AppConfig';
import PrivateRoute from '../../components/util-components/PrivateRoute';

export const AppViews: React.FC = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <PrivateRoute path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./Dashboard`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/breeder/list`}
                              component={lazy(() => import(`./Breeder/BreederList`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/breeder/view`}
                              component={lazy(() => import(`./Breeder/BreederView`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/charity/list`}
                              component={lazy(() => import(`./Charity/CharityList`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/charity/view`}
                              component={lazy(() => import(`./Charity/CharityView`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/vet/list`} component={lazy(() => import(`./Vet/VetList`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/vet/view`} component={lazy(() => import(`./Vet/VetView`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/sub-vet/list`}
                              component={lazy(() => import(`./SubVet/SubVetList`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/sub-vet/view`}
                              component={lazy(() => import(`./SubVet/SubVetView`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/buyer/list`}
                              component={lazy(() => import(`./Buyer/BuyerList`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/buyer/view`}
                              component={lazy(() => import(`./Buyer/BuyerView`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/advert/list`}
                              component={lazy(() => import(`./Advert/AdvertList`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/advert/view`}
                              component={lazy(() => import(`./Advert/AdvertView`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/microchip/list`}
                              component={lazy(() => import(`./Microchip/MicrochipList`))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/order/list`}
                              component={lazy(() => import('./Order/OrderList'))}/>
                <PrivateRoute path={`${APP_PREFIX_PATH}/pet-alert/list`}
                              component={lazy(() => import('./PetAlert/PetAlertList'))}/>
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`}/>
            </Switch>
        </Suspense>
    );
};

export default React.memo(AppViews);
