import React from 'react';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from '../../constants/ThemeConstant';
import { APP_NAME } from '../../configs/AppConfig';
import { connect } from 'react-redux';
import utils from '../../utils';
import { Grid } from 'antd';

const {useBreakpoint} = Grid;

const getLogoWidthGutter = (props: any, isMobile: boolean) => {
    const {navCollapsed, navType} = props;
    const isNavTop = navType === NAV_TYPE_TOP ? true : false;
    if (isMobile && !props.mobileLogo) {
        return 0;
    }
    if (isNavTop) {
        return 'auto';
    }
    if (navCollapsed) {
        return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
        return `${SIDE_NAV_WIDTH}px`;
    }
};

const getLogo = (props: any) => {
    // const { navCollapsed, logoType } = props;
    // if(logoType === 'light') {
    //   if(navCollapsed) {
    //     return '/img/logo-sm-white.png'
    //   }
    //   return '/img/logo-white.png'
    // }

    const {navCollapsed} = props;

    if (navCollapsed) {
        return '/img/app-logo-sm.png';
    }
    return '/img/app-logo.svg';

};

const getLogoDisplay = (isMobile: boolean, mobileLogo: any) => {
    if (isMobile && !mobileLogo) {
        return 'd-none';
    } else {
        return 'logo';
    }
};

interface Props {
    mobileLogo?: any;
    navCollapsed?: boolean;
    logoType?: any;
}

export const Logo: React.FC<Props> = (props) => {
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
    return (
        <div
            className={getLogoDisplay(isMobile, props.mobileLogo)}
            style={{width: `${getLogoWidthGutter(props, isMobile)}`}}>
            <img src={getLogo(props)} alt={`${APP_NAME} logo`} width={props.navCollapsed ? '50px' : '200px'}/>
        </div>
    );
};

// @ts-ignore
const mapStateToProps = ({theme}) => {
    const {navCollapsed, navType} = theme;
    return {navCollapsed, navType};
};

export default connect(mapStateToProps)(Logo);
