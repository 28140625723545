import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import navigationConfig from '../../configs/NavigationConfig';
import IntlMessage from '../util-components/IntlMessage';

let breadcrumbData = {
    '/app': <IntlMessage id="home"/>
};

navigationConfig.forEach((elm, i) => {
    // @ts-ignore
    const assignBreadcrumb = (obj) => breadcrumbData[obj.path] = <IntlMessage id={obj.title}/>;
    assignBreadcrumb(elm);
    if (elm.submenu) {
        elm.submenu.forEach(elm => {
            assignBreadcrumb(elm);
            if (elm.submenu) {
                elm.submenu.forEach(elm => {
                    assignBreadcrumb(elm);
                });
            }
        });
    }
});

const BreadcrumbRoute = withRouter(props => {
    const {location} = props;
    const pathSnippets = location.pathname.split('/').filter((i: any) => i);
    const buildBreadcrumb = pathSnippets.map((_: string, index: number) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return (
            <Breadcrumb.Item key={url}>
                {/*@ts-ignore*/}
                <Link to={url}>{breadcrumbData[url]}</Link>
            </Breadcrumb.Item>
        );
    });

    return (
        <Breadcrumb>
            {buildBreadcrumb}
        </Breadcrumb>
    );
});

export const AppBreadcrumb: React.FC = () => {
    return (
        <BreadcrumbRoute/>
    );
};

export default AppBreadcrumb;
